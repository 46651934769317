import { useState } from "react"
import { FileUploader } from "react-drag-drop-files";
import { Button, FloatingLabel,Form, Image } from "react-bootstrap";
import logoImage from '../../assets/embedai-logo.png'
import linkImage from '../../assets/link.svg'
import fileImage from '../../assets/file.svg'

function BasicOnboarding({startTraining}) {
    const [name,setName] = useState('')
    const [linkOrFile,setLinkOrFile] = useState(null)
    const [link,setLink] = useState('')
    return (
        <div className={`box theme-light py-3 px-3 px-lg-4 d-flex flex-column align-items-center h-100`}>
            <div className="center-container w-100 d-flex flex-column align-items-center gap-3">
                <div className="d-flex flex-column align-items-center">
                    <span className="m-0">Welcome to </span>
                    <div className="logo-image position-relative mb-2">
                        <Image objectFit="contain" fill={true} className="heybotnew-logo-image" src={logoImage}/>
                    </div>
                    <span>Let's create your first chatbot</span>
                </div>
                <div className="d-flex w-100  flex-column gap-1">
                    <small className="fw-medium">Name your knowledgebase chatbot </small>
                    <FloatingLabel
                        className="w-100"
                        label="Knowledgebase Name"
                    >
                        <Form.Control value={name} onChange={(e) => setName(e.target.value)} placeholder="Knowledgebase Name" className={` px-lg-3 input  theme-light`} />
                    </FloatingLabel>
                </div>
                <div className="divider-horizontal align-self-start"></div>

                {
                    linkOrFile == null ?
                    <div className="d-flex flex-column gap-3 w-100">
                        <small className="fw-medium">Add sources to train your chatbot</small>
                        <div class="d-flex align-items-center fw-medium">
                            Web Pages
                        </div>
                        <small >EmbedAI extracts content from the designated website in order to facilitate the training of your AI chatbot. The extracted content may include text, image alt texts,youtube video captions or other forms of data that can help the chatbot better understand and respond to user queries and provide a more informed and contextually relevant experience.</small>
                        <Button className=" share-button align-self-start py-2 px-4" onClick={() => { setLinkOrFile(0) }}>
                            Add a link
                        </Button>

                    </div> :
                    <div className="d-flex w-100 flex-column gap-1 position-relative">
                        <b onClick={() => { setLinkOrFile(null); setLink('') }} className="position-absolute cursor-pointer top-0 end-0">x</b>
                        <small className="fw-medium">Add a link</small>
                        <FloatingLabel
                            className="w-100"
                            label="Web or Youtube Link"
                        >
                            <Form.Control value={link} onChange={(e) => setLink(e.target.value)} placeholder="Web or Youtube Link" className={` px-lg-3 input theme-light`} />
                        </FloatingLabel>
                        <small style={{opacity:0.6,fontSize:'0.7rem'}}>You can add / edit sources later</small>
                        {link != '' && name != '' ?
                            <Button className=" share-button align-self-start mt-3 py-2 px-4" onClick={()=>startTraining(name,link)}>
                                Continue
                            </Button> :
                            <Button className=" share-button align-self-start mt-3 py-2 px-4" disabled>
                                Continue
                            </Button>
                        }
                    </div>
                }
            </div>
        </div>
    );
}

export default BasicOnboarding;
