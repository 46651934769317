import { useState, useEffect, useRef } from "react";
import {
  Stack,
  Button,
  InputGroup,
  FormControl,
  Modal,
  Image,
  Dropdown,
  Spinner,
  Row,
  Col,
  Offcanvas,
  Form,
  Container,
  Accordion,
} from "react-bootstrap";
import "../styles/clone.css";
import { useNavigate } from "react-router-dom";
import { ReactComponent as SearchIcon } from "../assets/search.svg";
import { ReactComponent as GoogleIcon } from "../assets/google-logo.svg";
import { ReactComponent as CopyIcon } from "../assets/copy.svg";
import { ReactComponent as SendIcon } from "../assets/send-icon.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import logoImage from "../assets/mygpt-new-logo4.png";
import userImage from "../assets/user.png";
import { ReactComponent as QuestionIcon } from "../assets/question-mark.svg";
import { ReactComponent as ShareIcon } from "../assets/share.svg";
import { ReactComponent as ShareWhiteIcon } from "../assets/share-white.svg";
import { ReactComponent as DiscordIcon } from "../assets/discord.svg";
import { ReactComponent as TwitterIcon } from "../assets/twitter.svg";
import { ReactComponent as StarIcon } from "../assets/star.svg";
import { ReactComponent as AddIcon } from "../assets/add-icon.svg";
import { ReactComponent as DeleteIcon } from "../assets/delete-icon.svg";
import { ReactComponent as ChatIcon } from "../assets/chat-icon.svg";
import { ReactComponent as PluginIcon } from "../assets/plugin-icon.svg";
import { ReactComponent as PromptIcon } from "../assets/prompt-icon.svg";
import { ReactComponent as ModelIcon } from "../assets/model-icon.svg";
import { ReactComponent as KeyIcon } from "../assets/key-mygpt.svg";
import { ReactComponent as LogoutIcon } from "../assets/logout-mygpt.svg";
import { ReactComponent as LightThemeIcon } from "../assets/light-mode-icon.svg";
import { ReactComponent as DarkThemeIcon } from "../assets/dark-mode-icon.svg";
import { ReactComponent as CheckIcon } from "../assets/check-icon.svg";
import { ReactComponent as MenuIcon } from "../assets/menu.svg";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import heybotLogo from "../assets/heybot.png";
import { useSearchParams, useParams } from "react-router-dom";
import axios from "axios";
import PromptModal from "../components/gptclone/PromptModal";
import PluginMenu from "../components/gptclone/PluginMenu";
import { ChatOpenAI } from "langchain/chat_models/openai";
import {
  SystemMessagePromptTemplate,
  HumanMessagePromptTemplate,
  ChatPromptTemplate,
  MessagesPlaceholder,
} from "langchain/prompts";
import { BufferMemory } from "langchain/memory";
import { LLMChain } from "langchain/chains";
import { SerpAPI,RequestsGetTool,
    RequestsPostTool,
    AIPluginTool, } from "langchain/tools";
import { ChatMessageHistory } from "langchain/memory";	
import { HumanChatMessage, AIChatMessage } from "langchain/schema";
import { ChainTool,Tool } from "langchain/tools";
import { initializeAgentExecutorWithOptions,ZeroShotAgent,ChatAgent, AgentExecutor } from "langchain/agents";
function ChatGPTClone() {
  document.title = "MyGPT";
  const [isLoggedIn, setIsLoggedIn] = useState(null);
  const [authUrl, setAuthUrl] = useState("");
  const [keyAdded, setKeyAdded] = useState(null);
  const [user, setUser] = useState({ id: 0, image: "", name: "User" });
  const [question, setQuestion] = useState("");
  const [chat, setChat] = useState([]);
  const [key, setKey] = useState("");
  const [APIkey, setAPIKey] = useState("");
  const [model, setModel] = useState("gpt-3.5-turbo");
  const [update, setUpdate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showPlugins, setShowPlugins] = useState(false);
  const [sessions, setSessions] = useState([]);
  const [plugins, setPlugins] = useState([]);
  const [customPlugins,setCustomPlugins] = useState([])
  const [customPlugin,setCustomPlugin] = useState({url:'',name:''})
  const [showMenu, setShowMenu] = useState(false);
  const [showCopy, setShowCopy] = useState(0);
  const [sessId, setSessId] = useState(0);
  const [streamAns, setStreamAns] = useState("");
  const [showBanner, setShowBanner] = useState(false);
  const [promptData, setPromptData] = useState({});
  const [selectedPrompt, setSelectedPrompt] = useState("");
  const [promptToDisplay, setPromptToDisplay] = useState("");
  const [showPromptModal, setShowPromptModal] = useState(false);
  const [imgError, setImgError] = useState(false);
  const [isDarkTheme, setIsDarkTheme] = useState(false);
  const keyLinks = {
    search: "https://serper.dev",
    wolfram: "https://products.wolframalpha.com/api/",
    zapier: "https://nla.zapier.com/demo/provider/debug",
  };
  const [keyFor, setKeyFor] = useState(null);
  const navigate = useNavigate();
  const chatRef = useRef(null);
  chatRef.current = chat;
  const sessionRef = useRef(null);
  sessionRef.current = sessId;
  const streamRef = useRef(null);
  streamRef.current = streamAns;
  let [searchParams, setSearchParams] = useSearchParams();
  const addKey = () => {
    axios
      .post("/store_key", { key: key })
      .then((res) => {
        setKeyAdded(true);
      })
      .catch((err) => {
        toast("Key cannot be verified, try again");
      });
  };
  const changeModel = (model) => {
    setModel(model);
    axios
      .post("/mygpt/change_model", { model: model })
      .then((res) => {})
      .catch((err) => {
        toast("Key cannot be verified, try again");
      });
  };
  function setCookie(name, value, days) {
    var expires = "";
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
  }

  function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == " ") c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }

  let saveConvo = (getQuestion, output) => {
    let prompt = "";
    let promptName = "";
    if (selectedPrompt != "") {
      prompt = selectedPrompt.prompt;
      promptName = selectedPrompt.act;
    }
    axios
      .post("/mygpt/save", {
        input: getQuestion,
        output: output,
        sessId: sessionRef.current,
        userPrompt: prompt,
        userPromptName: promptName,
      })
      .then((res) => {
        setSessId(res.data.sessId);
        if (res.data.isNew != null) {
          setSessions([res.data.isNew, ...sessions]);
        }
      })
      .catch((err) => {
        toast("Failed to respond " + err.response.data);
      });
  };

  let askClone = async () => {
    if(question===""){
      toast.error("Please enter valid input and try again.")
    }  else{
      let getQuestion = question;
      setChat((chat) => [...chat, { id: 0, isBot: false, msg: question }]);
      setQuestion("");
      var bl = getCookie("googtrans");
      if (bl == null) {
        bl = "en";
      }
      var sl = document.querySelector("html").getAttribute("lang");
      if (sl != null) {
        bl = "/en/" + sl;
      }
      let prompt = "";
      let promptName = "";
      if (selectedPrompt != "") {
        prompt = selectedPrompt.prompt;
        promptName = selectedPrompt.act;
      }
      if (plugins.length > 0 || customPlugins.length>0) {
        setLoading((prev) => true);
        axios
        .post("/mygpt/ask", {
          question: question,
          sessId: sessionRef.current,
          userPrompt: prompt,
          userPromptName: promptName,
          browsing_language: bl,
        })
        .then((res) => {
          setChat([
            ...chatRef.current,
            {
              id: res.data.msgId,
              isBot: true,
              msg: res.data.output,
              steps: res.data.steps,
              citations: res.data.citations,
            },
          ]);
          setSessId(res.data.sessId);
          setLoading((prev) => false);
          if (res.data.isNew != null) {
            setSessions([res.data.isNew, ...sessions]);
          }
        })
        .catch((err) => {
          toast("Failed to respond " + err.response.data);
        });
      } else {
        let languages = {
          en: "English",
          "/en/it": "Italian",
          "/en/ru": "Russian",
          "/en/es": "Spanish",
          "/en/de": "German",
          "/en/fr": "French",
          "/en/hi": "Hindi",
          "/en/pt": "Portuguese",
          "/en/zh-CN": "Chinese Simplified",
          "/en/zh-TW": "Chinese Traditional",
          "/en/cs": "Czech",
        };
        let language;
        let add_prompt = "";
        let getPrompt;
        let input_info = {};
        let output_info = {};

        language = languages[bl];

        if (language == undefined) {
          language = "English";
        }

        if (language != "English") {
          add_prompt = "Give output in " + language;
        }

        let llm = new ChatOpenAI({
          openAIApiKey: key,
          streaming: true,
          modelName: model,
          maxRetries: 4,
          callbacks: [
          {
          handleLLMNewToken(token: string) {
              setStreamAns(streamRef.current.concat(token))
          },
          handleChainError(err: Error, verbose?: boolean) {
              console.log("Error chain", err);
          },
          handleLLMError(err: Error, verbose?: boolean) {
              console.log("Error llm", err);
              if(err.toString().includes("Expected content-type to")) {
                  toast("You exceeded your current quota, please check your plan and billing details.");
              }
          },
          },
          ],
        });
        if (model == "gpt-4") {
          getPrompt =
          "You are GPT-4, a large language model trained by OpenAI." +
          add_prompt;
        } else {
          getPrompt =
          "You are ChatGPT, a large language model trained by OpenAI." +
          add_prompt;
        }
        if (prompt != "") {
          getPrompt = prompt + add_prompt;
        }

        let template = getPrompt;
        let getChat = ""
	    const pastMessages = [];
        chatRef.current.forEach(msg => {
            if(msg.isBot==true){
				pastMessages.push(new AIChatMessage(msg.msg))
                getChat = getChat.concat("\nAssistant:")

            }else{
				pastMessages.push(new HumanChatMessage(msg.msg))
                getChat = getChat.concat("\nHuman:")
            }
            getChat = getChat.concat(msg.msg)
        });
	    let memory = new BufferMemory({ returnMessages: true, memoryKey: "chat_history",inputKey:"input",outputKey:"output", chatHistory: new ChatMessageHistory(pastMessages)})
        
        setChat((chat)=>[...chat,{id:0,isBot:true,msg:null}])

           
        const chat_prompt = ChatPromptTemplate.fromPromptMessages([
            SystemMessagePromptTemplate.fromTemplate(template),
            new MessagesPlaceholder("chat_history"),
            HumanMessagePromptTemplate.fromTemplate("{input}"),
        ]);
           
        const samurai_convo = new LLMChain({ llm: llm, prompt: chat_prompt, verbose:false, memory: memory  });
        samurai_convo.predict({input:question}).then(output=>{chatRef.current.at(-1).msg=output;setStreamAns((prev)=>"");saveConvo(getQuestion,output) })
      }
	}
  };

  let loadChat = (sessionId) => {
    setShowMenu(false);
    setQuestion("");
    localStorage.removeItem("chat");
    if (sessionId == 0) {
      setChat([]);
      setSelectedPrompt("");
      setSessId(0);
    } else {
      setLoading((prev) => true);
      axios
        .post("/mygpt/load_chat", { sessId: sessionId })
        .then((res) => {
          setChat(res.data.msgs);
          if (res.data.promptName !== "") {
            setSelectedPrompt({
              act: res.data.promptName,
              prompt: res.data.prompt,
            });
          } else {
            setSelectedPrompt("");
          }
          setLoading((prev) => false);
          setSessId(res.data.sessId);
        })
        .catch((err) => {
          toast("Could not load chat, try again");
        });
    }
  };
  let deleteChat = (sessionId) => {
    axios
      .post("/mygpt/delete_chat", { sessId: sessionId })
      .then((res) => {
        if (sessionRef.current == sessionId) {
          setSelectedPrompt("");
          setChat([]);
          setSessId(0);
        }
        const result = sessions.filter((session) => session.id !== sessionId);
        setSessions(result);
      })
      .catch((err) => {
        toast("Could not delete chat, try again");
      });
  };

    let searchChat = (search) => {
        axios.post("/mygpt/search_chat",{search:search}).then((res)=>{
            setSessions(res.data)
           
        }).catch((err)=>{
            toast("Could not find chat, try again");
        })
    }
    const logout = () => {
        axios.get("/heybot/logout").then((res)=>{
            window.location.reload();
           }).catch((err)=>{
            console.log(err)
           })
    }
    let checkPlugin = async() => {
        /*let getLink = customPlugin.url+"/.well-known/ai-plugin.json"
        try {
            await AIPluginTool.fromPluginUrl(
                getLink
                ).then((output)=>{
		*/			
                    axios.post("/mygpt/save_plugin",{url:customPlugin.url,name:customPlugin.name}).then((res)=>{
                        setCustomPlugins([...customPlugins,customPlugin])
                        setKeyFor(null)
                        setAPIKey("")
                        setShowPlugins(false)
                       }).catch((err)=>{
                        console.log(err)
                       })
		/*			   
                })   
        } catch (error) {
            let errMsg = "URL Error: ".concat(String(error))
            toast(errMsg);

    }
*/
  }
  useEffect(() => {
    
    if (searchParams.get("addChat") != null) {
      localStorage.setItem("chat", searchParams.get("addChat"));
    }
    // console.log("bl", searchParams.get("bl"));
    if (localStorage.getItem("isDarkTheme") != null) {
      console.log(localStorage.getItem("isDarkTheme"))
    
      setIsDarkTheme(localStorage.getItem("isDarkTheme")=="false"?false:true)
    }
    if (searchParams.get("bl") != null) {
      console.log(
        "getCookie",
        getCookie("googtrans"),
        getCookie("googtrans") != searchParams.get("bl")
      );
      if (getCookie("googtrans") == null) {
        setCookie("googtrans", searchParams.get("bl"), 1);
        setTimeout(function () {
          window.location.reload();
        }, 1000);
      } else if (getCookie("googtrans") != searchParams.get("bl")) {
        setCookie("googtrans", searchParams.get("bl"), 1);
      }
    }
    axios
      .get("/mygpt/isLoggedIn")
      .then((res) => {
        setIsLoggedIn(res.data.isLoggedIn);
        if (res.data.isLoggedIn == false) {
          setAuthUrl(res.data.auth_url);
        } else {
          setUser({
            id: res.data.userId,
            image: res.data.image,
            name: res.data.name,
          });
          if (res.data.key_added == null) {
            setKeyAdded((prev) => false);
          } else {
            setKeyAdded((prev) => true);
            setKey(res.data.key_added);
          }
          setSessions(res.data.sessions);
          setPromptData({
            writing: JSON.parse(res.data.prompts.writing),
            popular: JSON.parse(res.data.prompts.popular),
            custom: JSON.parse(res.data.prompts.custom),
          });
          setPlugins(res.data.pluginsAdded);
          setCustomPlugins(res.data.customPlugins)
          setModel(res.data.model);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    //window.setTimeout(()=>setShowBanner(true),30000)

    // promptSelect()
  }, []);

  useEffect(() => {
    if (isLoggedIn == true && keyAdded == true) {
      if (localStorage.getItem("plugin") != null) {
        //setKeyFor(localStorage.getItem('plugin'))
      }
      if (localStorage.getItem("chat") != null) {
        loadChat(localStorage.getItem("chat"));
      }
    }
  }, [isLoggedIn, keyAdded]);
  useEffect(() => {
    console.log( localStorage.getItem("isDarkTheme"))
   localStorage.setItem("isDarkTheme", isDarkTheme);
  }, [isDarkTheme])

  const removePlugin = (plugin) => {
	  	console.log("Remove plugin");
        axios.post("/mygpt/remove_plugin",{plugin:plugin}).then((res)=>{
            
            if(plugin.includes('custom: ')){
                let getName = plugin.replace('custom: ','')
                let newPlugins = customPlugins.filter(item => item.name !== getName)
                setCustomPlugins(newPlugins)
            }else{
                let newPlugins = plugins.filter(item => item !== plugin)
                setPlugins(newPlugins)
            }
            toast('Plugin Removed')
        }).catch((err)=>{
            toast("Could not find chat, try again");
        })
  }

  useEffect(() => {
    if (document.querySelector(".end-chat") != null) {
      document
        .querySelector(".end-chat")
        .scrollIntoView({ behavior: "smooth", block: "end" });
    }
  }, [chat]);

  const shareChat = () => {
    navigator.clipboard.writeText(
      window.location.host + "/mygpt/share?session=" + sessionRef.current
    );
    window.open("/mygpt/share?session=" + sessionRef.current, "_blank");
  };

  const copyMsg = (msg) => {
    navigator.clipboard.writeText(msg);
    toast("Copied");
  };

  let handleAddPlugin = (plugin, requireKey) => {
	console.log("Add plugin");  
    if (plugins != [] && plugins.includes(plugin)) {
      axios
        .post("/mygpt/remove_plugin", { plugin: plugin })
        .then((res) => {
          let newPlugins = plugins.filter((item) => item !== plugin);
          setPlugins(newPlugins);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      if(requireKey==0){

        axios
        .post("/mygpt/add_plugin", { plugin: plugin, key: APIkey })
        .then((res) => {
          if (res.data == true) {
            setPlugins([...plugins, plugin]);
          }
          setKeyFor(null);
          setAPIKey("");
          setShowPlugins(false);
        })
        .catch((err) => {
          console.log(err);
        });

      }else{
          setKeyFor(plugin)
      }
    }
  };

  const handleInputChanges = (e) => {
    setQuestion(e.target.value);
    e.target.style.height = "auto";
    e.target.style.height = e.target.scrollHeight + "px";
  };
  const handleImgError = () => {
    setImgError(true);
  };
  return (
    <>
      <Row
        className="vh-100 overflow-hidden  g-0"
        style={{ background: "#141718" }}
      >
        <Col className=" h-100 d-none d-xl-block side-bar-col">
          <Stack className="side-bar h-100  py-4 px-4 " gap={2}>
            <div id="google_translate_element"></div>
            <div className="d-flex justify-content-center">
              <h4 className="fw-bold ">
                <Image className="mygpt-logo p-2" src={logoImage} />
                MyGPT
              </h4>
            </div>

            <div className="mygpt-search-bg mt-3">
              <InputGroup className="">
                <InputGroup.Text className="border-0 bg-transparent">
                  <SearchIcon className="icon" />
                </InputGroup.Text>
                <FormControl
                  placeholder="Search Chats"
                  className="border-0 bg-transparent rounded-0 text-light"
                  onChange={(e) => searchChat(e.target.value)}
                  disabled={isLoggedIn?false:true}
                />
              </InputGroup>
            </div>
            
            <Stack gap={2} className="session-scroll mt-4">
              {sessions.length > 0 &&
                sessions.map((sess) => (
                  <div className="previous-sess w-100  d-flex justify-content-between align-items-center">
                  <div>
                      <ChatIcon className="mygpt-icon me-2" />
                    </div>
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => loadChat(sess.id)}
                      className="text-truncate"
                    >
                      {sess.msg}
                    </span>
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => deleteChat(sess.id)}
                      className="ms-auto"
                    >
                      <DeleteIcon className="mygpt-icon mygpt-icon-hover" />
                    </div>
                  </div>
                ))}
            </Stack>
            <Button onClick={() => loadChat(0)} className="mygpt-buttons ">
              <div className="d-flex justify-content-left">
                <AddIcon className="mygpt-icon ms-2"></AddIcon>{" "}
                <span className="ms-2">New Chat</span>
              </div>
            </Button>

            <Accordion flush className="mygpt-usermenu">
              <Accordion.Item eventKey="0">
                <Accordion.Header className="mygpt-usermenu">
                <Image
                    src={imgError === false ? user.image : userImage}
                    onError={handleImgError}
                    className={
                      imgError == false
                        ? "mygpt-userimage me-2"
                        : "mygpt-userimage me-2 p-1"
                    }
                    style={{ filter: "brightness(0) invert(1) " }}
                  />
                  {user.name}
                </Accordion.Header>
                <Accordion.Body>
                <div className="d-flex theme-switch" style={{color:"white"}} >
                <LightThemeIcon className="mygpt-icon"/>
                <Form.Check type="switch" id="theme-switch"  className="ms-2 "  checked={isDarkTheme} onChange={()=>{
                  setIsDarkTheme(!isDarkTheme)
                  
                }}/>
                <DarkThemeIcon className="mygpt-icon"></DarkThemeIcon>
                </div>
                  <div
                    style={{ cursor: "pointer", color: "white" }}
                    className="mb-1"
                    
                  >
                    <KeyIcon className="mygpt-icon me-2" />
                    <span onClick={() => isLoggedIn?setKeyAdded(false):""}>
                      Change OpenAI Key
                    </span>
                  </div>
                  
                  <div style={{ cursor: "pointer", color: "white" }}>
                    <LogoutIcon className="mygpt-icon me-2" />
                    <span onClick={logout}>Logout</span>
                  </div>
                  <div className="product-names mt-3">
                   <a className="me-1" href="https://autogpt.thesamur.ai/">AutoGPT</a> |
                   <a className="me-1 ms-1" href="https://heybot.thesamur.ai/">HeyBot</a> |
                   <a className="me-1 ms-1" href="https://ritebot.thesamur.ai/">RiteBot</a> |
                   <a className="me-1 ms-1" href="https://memegpt.thesamur.ai/">MemeJourney</a> |
                   <a className="me-1 ms-1" href="https://camelagi.thesamur.ai/">Camel AGI</a>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Stack>
        </Col>

        <Col
          className="main-chat-col ms-4 ms-sm-0"
          style={{
            background: isDarkTheme?"#232627":"white",
            height: "95%",
            borderRadius: "15px",
            marginTop: "20px",
            marginRight: "20px",
          }}
        >
          <div className="d-flex d-xl-none px-4 py-3 mb-3" style={{color:isDarkTheme?"white":"black"}}>
            <h4 className="fw-bold">
              <Image className="mygpt-logo me-2" src={logoImage} />
              <span >MyGPT</span>
            </h4>
            <div className=" ms-auto">
              <MenuIcon
                onClick={() => setShowMenu(true)}
                className="icon"
                style={{ filter:isDarkTheme?"brightness(0) invert(1) ":"",color:"black" }}
              />
            </div>
          </div>

          {isLoggedIn != null && (
            <Stack
              className="text-center mx-2 main-chat px-md-5 py-sm-4 align-items-center justify-content-center"
              gap={3}
            >
              {isLoggedIn ? (
                <>
                  <div
                    className="d-flex justify-content-between mb-sm--3 "
                    style={{ width: "100%" }}
                  >
                    <div className="d-flex align-items-center justify-content-center"  style={{ width: "100%" }}>
                      
                      <div
                        className="d-flex justify-content-center align-items-center function-btn ms-sm-5 me-sm-3 me-3"
                        style={{ backgroundColor: "#26519e ", color: "white",zIndex:"1000" }}
                      >
                        <ModelIcon className="mygpt-icon p-1" />
                        <Dropdown align="center" className="model-popup">
                          <Dropdown.Toggle id="dropdown-basic">
                            <div className="d-flex align-items-center justify-content-center">
                              <small className="text-nowrap">{model}</small>
                            </div>
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={() => changeModel("gpt-3.5-turbo")}
                            >
                              GPT-3.5-Turbo
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => changeModel("gpt-4")}>
                              GPT-4
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                      <Stack
                        className=" pill-scroll"
                        direction="horizontal"
                        gap={4}
                      >
                        <div
                          style={{
                            cursor: "pointer",
                            borderRadius: "12px",
                            backgroundColor: "#A4193D",
                            color: "white",
                          }}
                          onClick={() => setShowPlugins(true)}
                          className=" function-btn"
                        >
                          <div className="d-flex justify-content-center align-items-center">
                            <PluginIcon className="mygpt-icon p-1" />
                            <small className="text-nowrap">
                              {plugins != [] && plugins.length > 0
                                ? plugins.length + " Plugin added"
                                : "Add plugin"}
                            </small>
                          </div>
                        </div>
                      </Stack>
                    </div>
                    <div>
                      {sessionRef.current != 0 && (
                        <div
                          className={isDarkTheme?"d-flex align-self-end share-btn-dark":"d-flex align-self-end share-btn"}
                          onClick={shareChat}
                          
                        >
                        {isDarkTheme?<ShareWhiteIcon className="small-icon me-1" />:<ShareIcon className="small-icon me-1" />}
                          
                          
                          Share
                        </div>
                      )}
                    </div>
                  </div>
                  <Stack gap={2} className="chat-scroll msg-scroll">
                    {chat.length > 0 ? (
                      chat.map((msg) => (
                        <>
                          <div
                            onMouseOver={() => setShowCopy(msg.id)}
                            onClick={() => setShowCopy(msg.id)}
                            className={
                              msg.isBot
                                ? isDarkTheme?"d-flex gpt-msg-dark p-4 text-start w-100 position-relative":"d-flex gpt-msg p-4 text-start w-100 position-relative"
                                : isDarkTheme?"d-flex p-4 rounded text-start w-100 position-relative user-msg-dark":"d-flex p-4 rounded text-start w-100 position-relative user-msg"
                            }
                          >
                            <Image
                              className="profile-img rounded-circle me-3"
                              src={msg.isBot ? logoImage : user.image}
                            />
                            <div className="w-100">
                              {msg.steps &&
                                msg.steps.length > 0 &&
                                msg.steps.map((step) => (
                                  <div
                                    className="py-2 px-3 mb-3   steps-bg "
                                    gap={2}
                                  >
                                    <small className="text-dark">
                                      <b>Plugin Used:</b> {step}
                                    </small>
                                  </div>
                                ))}
                              <div>
                                {msg.isBot ? (
                                  <>
                                    {msg.msg == null ? (
                                      <div>
                                        <span>{streamAns}</span>
                                        <span class="blinking-cursor"> █</span>
                                      </div>
                                    ) : (
                                      <ReactMarkdown
                                        children={msg.msg}
                                        remarkPlugins={[remarkGfm]}
                                      />
                                    )}
                                  </>
                                ) : (
                                  msg.msg
                                )}
                              </div>
                              {msg.citations && msg.citations.length > 0 && (
                                <div className="d-flex gap-2 flex-wrap">
                                  {msg.citations.map((link) => (
                                    <span className="citations-bg py-2 px-3 text-truncate text-dark">
                                      <a target="_blank" href={link}>
                                        {link}
                                      </a>
                                    </span>
                                  ))}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="d-flex justify-content-end align-item-center">
                            {showCopy == msg.id && (
                              <div className={isDarkTheme?"share-btn-dark justify-content-center":"share-btn justify-content-center"}>
                                <CopyIcon
                                  onClick={() => copyMsg(msg.msg)}
                                  className=" small-icon me-1"
                                />
                                Copy
                              </div>
                            )}
                          </div>
                        </>
                      ))
                    ) : (
                      <Stack className="align-items-center ms-sm-5 ps-sm-3" gap={1} style={{color:isDarkTheme?"white":"black"}}>
                        {/*<Image className="mygpt-logo " src={logoImage}/>*/}
                        <h1 className="mt-sm-5"><small>MyGPT by SamurAI</small></h1>
                        <h3 style={{color:"gray"}}>
                        A better UI for ChatGPT 
                        </h3>
                        <Row className="mt-sm-3 ms-sm-4 ms-5 ps-4 ps-md-3 me-1 me-sm-0">
                          <Col className="text-start d-flex flex-column gap-2 text-nowrap mb-2" >
                            <div>
                              <CheckIcon className="mygpt-icon me-2" style={{color:"#2BAE66"}}/>
                              Free to use
                            </div>
                            <div>
                            <CheckIcon className="mygpt-icon me-2"  style={{color:"#2BAE66"}}/>
                            Share your chats
                            </div>
                            <div>
                            <CheckIcon className="mygpt-icon me-2"  style={{color:"#2BAE66"}}/>
                            Prompt Library
                            </div>
                          </Col>
                          <Col className="text-start d-flex flex-column gap-2 text-nowrap ms-xl-4" >
                            <div>
                            <CheckIcon className="mygpt-icon me-2 "  style={{color:"#2BAE66"}}/>
                            Plugin support
                            </div>
                            <div>
                            <CheckIcon className="mygpt-icon me-2"  style={{color:"#2BAE66"}}/>
                            Manage sessions
                            </div>
                            <div>
                            <CheckIcon className="mygpt-icon me-2"  style={{color:"#2BAE66"}}/>
                            Chat History and Search
                            </div>
                          </Col>
                          
                        </Row>
                        <div>
                          {key==null && key==="" ?<Button className="mt-4 mt-md-5 mygpt-enter-key-btn text-center" style={{width:"200px",textAlign:"center"}} onClick={() => {
                          setKeyAdded(false);}}>Enter OpenAI Key</Button>:""}
                          </div>
                      </Stack>
                    )}
                    {loading && (
                      <Stack
                        className="ms-3 loading"
                        direction="horizontal"
                        gap={2}
                      >
                        <Spinner size="sm" animation="grow" />
                        <Spinner size="sm" animation="grow" />
                        <Spinner size="sm" animation="grow" />
                      </Stack>
                    )}

                    <div className="end-chat"></div>
                  </Stack>
                  <div className="d-flex" style={{ width: "100%" }}>
                    <Stack
                      className=" pill-scroll mb-sm-3 mb-md-0"
                      direction="horizontal"
                      gap={2}
                    >
                      {selectedPrompt != "" && (
                        <div className="me-3  px-md-3 " style={{border:"1px solid #999999",borderRadius:"15px",color:isDarkTheme?"white":"black"}}>
                          <small>selected Prompt: {selectedPrompt.act}</small>
                          <b
                           style={{ cursor: "pointer"}}
                            onClick={() => setSelectedPrompt("")}
                            className="ms-2"
                          >
                            x
                          </b>
                        </div>
                      )}
                      <div
                        onClick={(e) => setShowPromptModal(true)}
                        className="me-3 function-btn"
                        style={{ backgroundColor: "#2BAE66", color: "white" }}
                      >
                        <div className="d-flex justify-content-center align-items-center">
                          <PromptIcon className="mygpt-icon p-1" />
                          <small className="text-nowrap">Add Prompt</small>
                        </div>
                      </div>
                    </Stack>
                  </div>
                  <div className={isDarkTheme?"d-flex justify-content-center mygpt-chat-input-container-dark mb-5 mb-sm-0":"d-flex justify-content-center mygpt-chat-input-container mb-5 mb-sm-0"}>
                    <FormControl
                      rows={1}
                      className={isDarkTheme?"mygpt-chat-input-dark p-2":"mygpt-chat-input p-2"}
                      value={question}
                      onChange={(e) => handleInputChanges(e)}
                      onKeyDown={(e) => {
                        e.code == "Enter" && !e.shiftKey && askClone();
                      }}
                      as="textarea"
                    />

                    <div
                      className="d-flex flex-column mx-2 mb-4 justify-content-end"
                      style={{ height: "100%",cursor:"pointer",color:question===""?"#4e4e4e":"#ffbb00"}}
                      onClick={askClone}
                    >
                      <SendIcon className="mygpt-icon" />
                    </div>
                  </div>
                  {/* <div class="w-100 px-2 d-flex flex-wrap justify-content-between">
                                <Stack className=" pill-scroll mb-3 mb-md-0" direction="horizontal" gap={2}>
                                    {selectedPrompt!=""&&
                                    <div style={{borderRadius:'12px'}} className="me-3 border border-dark border-2 px-2 px-md-3 py-1">
                                        <small>selected Prompt: {selectedPrompt.act}</small>
                                        <b style={{cursor:'pointer'}} onClick={()=>setSelectedPrompt("")} className="ms-2">x</b>
                                    </div>}
                                    <div style={{cursor:'pointer',borderRadius:'12px'}} onClick={(e)=>setShowPromptModal(true)} className="me-3 border border-dark border-2 px-3 py-1">
                                        <small className="text-nowrap">Add Prompt</small>
                                        <b className="ms-2">+</b>
                                    </div>
                                </Stack>
                                <Stack className=" pill-scroll" direction="horizontal" gap={2}>
                                    {customPlugins.length>0&&customPlugins.map((plugin)=><div style={{borderRadius:'12px'}} className="me-3 border border-dark border-2 px-2 px-md-3 py-1">
                                        <small className="text-nowrap">{plugin.name} plugin</small>
                                        <b style={{cursor:'pointer'}} onClick={()=>removePlugin("custom: "+plugin.name)} className="ms-2">x</b>
                                    </div>)}
                                    {plugins!=[]&&plugins.map((plugin)=><div style={{borderRadius:'12px'}} className="me-3 border border-dark border-2 px-2 px-md-3 py-1">
                                        <small className="text-nowrap">{plugin} plugin</small>
                                        <b style={{cursor:'pointer'}} onClick={()=>removePlugin(plugin)} className="ms-2">x</b>
                                    </div>)}
                                    <div style={{cursor:'pointer',borderRadius:'12px'}} onClick={()=>setShowPlugins(true)} className="me-3 border border-dark border-2 px-3 py-1">
                                        <small className="text-nowrap">Add Plugin</small>
                                        <b className="ms-2">+</b>
                                    </div>
                                </Stack>
                            </div> */}
                </>
              ) : (
                <>
                  <Container style={{ height: "100%" }}>
                    <Row
                      className="justify-content-center mt-5 pt-5"
                      style={{ color:isDarkTheme?"white":"black"}}
                    >
                      <Col>
                        <Image className="mygpt-logo p-1" src={logoImage} />
                        <h1 className="mt-sm-3"><small>MyGPT by SamurAI</small></h1>
                        <h3 style={{color:"gray"}}>
                        A better UI for ChatGPT 
                        </h3>
                      </Col>
                    </Row>
                    <Row className="mt-5 ">
                      <Col>
                        <Button className="mygpt-google-btn" href={authUrl}>
                          <GoogleIcon className="icon me-2 pb-1" /> Login with
                          Google
                        </Button>
                        <div
                          style={{ cursor: "pointer", opacity: "0.8",color:isDarkTheme?"white":"black" }}
                          className="mt-auto align-self-center pt-4"
                          onClick={() => navigate("/mygpt/faq")}
                        >
                          <QuestionIcon className="icon" style={{ filter: isDarkTheme?"brightness(0) invert(1) ":"" }}/> <b>Know More</b>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </>
              )}
            </Stack>
          )}
        </Col>
      </Row>
      <Offcanvas
        className="side-bar"
        show={showMenu}
        onHide={() => setShowMenu(false)}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>MyGPT</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Stack className="h-100  text-center" gap={2}>
            <Stack gap={3} className="session-scroll">
            <Button onClick={() => loadChat(0)} className="mygpt-buttons">
              <div className="d-flex justify-content-center">
                <AddIcon className="mygpt-icon"></AddIcon>
                <span className="ms-2">New Chat</span>
              </div>
            </Button>
              <div className="mygpt-search-bg mt-3">
              <InputGroup className="">
                <InputGroup.Text className="border-0 bg-transparent">
                  <SearchIcon className="icon" />
                </InputGroup.Text>
                <FormControl
                  placeholder="Search Chats"
                  className="border-0 bg-transparent rounded-0 text-light"
                  onChange={(e) => searchChat(e.target.value)}
                  disabled={isLoggedIn?false:true}
                />
              </InputGroup>
            </div>
              
              {sessions.length > 0 &&
                sessions.map((sess) => (
                  <div className="previous-sess w-100  d-flex justify-content-between align-items-center">
                    <div>
                      <ChatIcon className="mygpt-icon me-2" />
                    </div>
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => loadChat(sess.id)}
                      className="text-truncate"
                    >
                      {sess.msg}
                    </span>
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => deleteChat(sess.id)}
                      className="ms-auto"
                    >
                      <DeleteIcon className="mygpt-icon mygpt-icon-hover" />
                    </div>
                  </div>
                ))}
            </Stack>
            <div className="d-flex w-100 align-item-center justify-content-center theme-switch" style={{color:"white"}} >
                <LightThemeIcon className="mygpt-icon"/>
                <Form.Check type="switch" id="theme-switch"  className="ms-2 "  checked={isDarkTheme} onChange={()=>{
                  setIsDarkTheme(!isDarkTheme)
                  localStorage.setItem("isDarkTheme", isDarkTheme);
                }}/>
                <DarkThemeIcon className="mygpt-icon"></DarkThemeIcon>
                </div>
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                setKeyAdded(false);
                setShowMenu(false);
              }}
            >
              Change OpenAI Key
            </span>

            <span style={{ cursor: "pointer" }} onClick={logout}>
              Logout
            </span>
          </Stack>
        </Offcanvas.Body>
      </Offcanvas>
      <ToastContainer />
      <Modal
        className="banner-modal"
        centered
        show={showBanner}
        onHide={() => setShowBanner(false)}
      >
        <Modal.Body className="p-5 position-relative">
          <b
            style={{ cursor: "pointer" }}
            onClick={() => setShowBanner(false)}
            className="position-absolute top-0 end-0 me-4 mt-2"
          >
            X
          </b>
          <Stack className="align-items-center" gap={3}>
            <a
              href="https://www.producthunt.com/posts/chatgpt-plugins-5?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-chatgpt&#0045;plugins&#0045;5"
              target="_blank"
            >
              <img
                src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=386004&theme=light"
                alt="ChatGPT&#0032;Plugins - Access&#0032;to&#0032;chatGPT&#0032;plugins&#0032;without&#0032;ChatGPT&#0032;Plus | Product Hunt"
              />
            </a>
            <a
              className="discord-invite px-3 py-3 ms-3"
              target="_blank"
              href="https://discord.gg/KZSUEjr4TM"
            >
              <DiscordIcon className="icon me-1" /> Join Our Discord
            </a>
            <a
              className="discord-invite px-3 py-3 ms-3"
              target="_blank"
              href="http://twitter.com/share?text=Free and faster ChatGPT pro with prompt support, Check out MyGPT by SamurAI&url=https://mygpt.thesamur.ai&hashtags=ai,gpt,chatgpt"
            >
              <TwitterIcon className="icon me-1" /> Share on Twitter
            </a>
            <a
              className="heybot-button px-3 py-3 ms-3"
              target="_blank"
              href="https://heybot.thesamur.ai"
            >
              <Image src={heybotLogo} className="icon me-1" /> Convert your
              website into a chatbot
            </a>
          </Stack>
        </Modal.Body>
      </Modal>
      { <Modal className="banner-modal" centered show={keyFor!=null} onHide={()=>setKeyFor(null)}>
                <Modal.Body className="p-5 position-relative">
                    <b style={{cursor:'pointer'}} onClick={()=>setKeyFor(null)} className="position-absolute top-0 end-0 me-4 mt-2">X</b>
                    {(keyFor!=null&&keyFor=="custom")&&<Stack className="align-items-center" gap={3}>
                        <span>Make sure your server has a './well-known/ai-plugin.json' with the following format</span>
                        <Accordion className="w-100" >
                            <Accordion.Item className=" " eventKey="0">
                                <Accordion.Header><small>ai-plugin.json</small></Accordion.Header>
                                <Accordion.Body className="p-0 task-scroll">
                                    <pre className="overflow-hidden">
                                    {`
{   
    "schema_version": "v1",
    "name_for_human": "NAME",
    "name_for_model": "NAME",
    "description_for_human": "DESCRIPTION",
    "description_for_model": "DESCRIPTION",
    "auth": {
        "type": "none"
    },
    "api": {
        "type": "openapi",
        "url": "SERVER_ROUTE/openapi.yaml",
        "is_user_authenticated": false
    },
    "logo_url": "SERVER_ROUTE/image",
    "contact_email": "EMAIL",
    "legal_info_url": "SERVER_ROUTE/legal"     
}
                                    `}
                                    </pre>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                        <span>And add the yaml file to your server with the following format</span>
                        <Accordion className="w-100" >
                            <Accordion.Item className=" " eventKey="0">
                                <Accordion.Header><small>openapi.yaml</small></Accordion.Header>
                                <Accordion.Body className="p-0 task-scroll">
                                    <pre className="">
                                    {`
openapi: 3.0.1
info:
    title: TITLE
    description: DESCRIPTION
    version: 'v1'
servers:
    - url: SERVER_ROUTE
paths:
    /ROUTE:
    get: 
    # max 200 characters for each API endpoint description/summary field and each API param description field in API specification

        operationId: KEYWORD # use a meaningful name and include prompt-related keyword
        summary: SUMMARY # include keywords for the prompt as much as possible
        responses:
        '200':
            description: Successful action
            content:
            application/json:
                schema:
                type: object
                properties:  # json object schema here 
                    message:
                    type: string
                    example: 'This is an example text'
                                    `}
                                    </pre>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                        <div className="w-100">
                            <Form.Label>Enter name for your custom plugin</Form.Label>
                            <Form.Control onChange={(e)=>setCustomPlugin({url:customPlugin.url,name:e.target.value})}/>
                        </div>
                        <div className="w-100">
                            <Form.Label>Enter server URL</Form.Label>
                            <Form.Control onChange={(e)=>setCustomPlugin({url:e.target.value,name:customPlugin.name})}/>
                        </div>
                        <Button onClick={checkPlugin}>Save</Button>
                    </Stack>}
                </Modal.Body>
            </Modal>  }
      <Modal
        className="plugins-modal"
        centered
        show={showPlugins}
        onHide={() => setShowPlugins(false)}
      >
        <Modal.Body className="p-5 position-relative" style={{backgroundColor:isDarkTheme?"#2b2d2f ":"white",color:isDarkTheme?"white":"black"}}>
          <h5 className="text-center fw-bold mb-3">Plugins</h5>
          <b
            style={{ cursor: "pointer" }}
            onClick={() => setShowPlugins(false)}
            className="position-absolute top-0 end-0 me-4 mt-2"
          >
            X
          </b>
          {keyFor != null ? (
            <Stack className="align-items-center" gap={3}>
              <Form.Label>
                Enter your{" "}
                <a target="_blank" href={keyLinks[keyFor]}>
                  {keyFor}
                </a>{" "}
                API key
              </Form.Label>
              <Form.Control onChange={(e) => setAPIKey(e.target.value)} />
              <Button onClick={() => handleAddPlugin(keyFor, 0)}>Save</Button>
            </Stack>
          ) : (
            <PluginMenu
              isLoggedIn={isLoggedIn}
              plugins={plugins}
              handleAddPlugin={handleAddPlugin}
			  removePlugin={removePlugin}
			  customPlugins={customPlugins}
            />
          )}
        </Modal.Body>
      </Modal>
      <Modal
        className="key-modal"
        centered
        show={keyAdded == false}
        onHide={() => setKeyAdded(null)}
        
      >
        <Modal.Body className="p-3 p-md-5 position-relative"   style={{backgroundColor:isDarkTheme?"#2b2d2f ":"white",color:isDarkTheme?"white":"black"}}>
          <b
            style={{ cursor: "pointer" }}
            onClick={() => setKeyAdded(null)}
            className="position-absolute top-0 end-0 me-4 mt-2"
          >
            X
          </b>
          <Stack className="align-items-center" gap={3}>
            <h4>
              <b>Add your OpenAI Key</b>
            </h4>
            <p>
              <small>
                Get your OpenAI Key by signing up/ logging in from the OpenAI
                Dashboard.{" "}
              </small>
              <a
                target="_blank"
                href="https://platform.openai.com/account/api-keys"
              >
                Go to Dashboard
              </a>
            </p>
            <InputGroup>
              <FormControl
                className="chat-input px-md-5 py-md-2 shadow-none"
                style={{ height: "48px" }}
                value={key}
                onChange={(e) => setKey(e.target.value)}
                onKeyDown={(e) => {
                  e.code == "Enter" && addKey();
                }}
              />
              <Button
                variant="outline-secondary"
                className="chat-button text-dark fw-bold ps-md-3 pe-md-5 py-md-2"
                onClick={addKey}
              >
                <SendIcon className="icon" />
              </Button>
            </InputGroup>
            <small>Watch this video to get started</small>
            <iframe
              className="key-video"
              src="https://www.youtube.com/embed/FaJdwbNWNkk"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </Stack>
        </Modal.Body>
      </Modal>
      {promptData != {} && Object.keys(promptData).length !== 0 && (
        <PromptModal
          data={promptData}
          setSelectedPrompt={setSelectedPrompt}
          setShowModal={setShowPromptModal}
          showModal={showPromptModal}
          setQuestion={setQuestion}
          darkTheme={isDarkTheme}
        ></PromptModal>
      )}
    </>
  );
}

export default ChatGPTClone;
