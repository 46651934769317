import { Form, Stack, Accordion, ListGroup,InputGroup, Image} from 'react-bootstrap'
import { ReactComponent as ShareIcon } from "../../assets/share-embedai.svg";
import { ReactComponent as SearchIcon } from "../../assets/search.svg";
import { ReactComponent as PlusIcon } from "../../assets/plus-black.svg";
import { ReactComponent as PlusWhiteIcon } from "../../assets/plus-white.svg";
import { ReactComponent as JoinIcon } from "../../assets/join-membership.svg";
import { ReactComponent as CustomizeIcon } from "../../assets/customize-embedai.svg";
import { ReactComponent as IntegrationIcon } from "../../assets/integrations.svg";
import { ReactComponent as EditIcon } from "../../assets/settings-embedai.svg";
import { ReactComponent as HistoryIcon } from "../../assets/chat-embedai.svg";
import { ReactComponent as ReferIcon } from "../../assets/refer.svg";
import { ReactComponent as LogoutIcon } from "../../assets/logout-heybot.svg";
import { ReactComponent as BackIcon } from "../../assets/back.svg";

import { useState,useEffect } from "react";
import axios from "axios";
import logoImage from '../../assets/embedai-logo.png'
import { useNavigate } from "react-router-dom";

function Navbar({userName,theme,KBases,currentKBase,setCurrentKBase,setShowNewKBase,setShowKBSettingModal,setShowShare,setSession,loadKnowledgebase,queries,setShowReferral,setChat,setShowUpgrade,setShowIntegrationModal,setForVision,setVisionImage,setVisionImageAdded}) {
    const [update,setUpdate] = useState(false)
    const [historySessions,setHistorySessions] = useState([])

    const [showHistory,setShowHistory] = useState(false)

    const navigate = useNavigate()

    let loadConvo = (sessId) => {
        axios.post("/heybotnew/load_convo",{sessId:sessId}).then((res)=>{
            setChat(res.data.msgs)
            setVisionImage(res.data.visionImage)
            if(res.data.visionImage!=""){
                setVisionImageAdded((prev)=>true)
            }
            }).catch((err)=>{
            console.log(err)
        })
    }
    let searchChat = (search) => {
        axios.post("/heybotnew/search_chat",{search:search,kbase_id:currentKBase.id}).then((res)=>{
            setHistorySessions(res.data)
           
        }).catch((err)=>{
            console.log("Could not find chat, try again");
        })
    }

    let logout = () => {
        axios.get("/logout").then((res)=>{
            window.location.reload();
        }).catch((err)=>{
            console.log(err)
        })
    }

    const removeSession = (id) => {
        axios.post("/heybotnew/delete_convo",{sessId:id}).then((res)=>{
            currentKBase.sessions = currentKBase.sessions.filter((sess)=>sess.id!==id)
            setHistorySessions(historySessions.filter((sess)=>sess.id!==id))
            setUpdate((prev)=>!prev)
            }).catch((err)=>{
            console.log(err)
        })
    }

    useEffect(()=>{
        if(currentKBase!=null&&currentKBase.id!=0){
            setHistorySessions(currentKBase.sessions)
        }
    },[showHistory])

    return(
        <x>
           {showHistory?
            <Stack className="heybotnew-navbar-container align-items-start" gap={3}>
                <div class="d-flex w-100 justify-content-between fw-bold">
                    <BackIcon className="heybotnew-image" onClick={()=>setShowHistory(false)} style={{cursor:"pointer"}}/>
                </div>
                <InputGroup className="my-2">
                    <InputGroup.Text style={{background:'#362f2e'}} className="border-0 ">
                    <SearchIcon className="icon" />
                    </InputGroup.Text>
                    <Form.Control style={{height:'2rem'}} onChange={(e)=>searchChat(e.target.value)} placeholder="Search Chats" className={`heybotnew-history-input ${theme==0?"theme-light":"theme-dark"}`}/>

                    
                </InputGroup>

                {((currentKBase!=null&&currentKBase.id!=0)&&historySessions.length>0)&&
                <Stack style={{maxHeight:'8rem'}} className="h-100" gap={2}>
                    {historySessions.filter((sess)=>sess.isLive==1).length >0 &&
                    <>
                        <small style={{fontSize:'10px'}}>Live Conversations</small>
                        {historySessions.filter((sess)=>sess.isLive==1).map((session)=><div className={`d-flex border-btn border-btn-hover position-relative`} >
                            <div className="cursor-pointer me-2 text-truncate" onClick={()=>{setSession(session);loadConvo(session.id);setShowHistory(false)}}>{session.msg}</div>
                            <svg class="live-blinking ms-auto me-2">
                                <circle cx="25" cy="15" r="5" fill="red" />
                            </svg>
                            <b className="position-absolute top-0 end-0 me-2 cursor-pointer" onClick={()=>removeSession(session.id)}>x</b>
                        </div>)}
                        <hr></hr>
                    </>
                    }
                    <small style={{fontSize:'10px'}}>Previous Conversations</small>

                    {historySessions.filter((sess)=>sess.isLive==0).map((session)=><div className={`d-flex border-btn border-btn-hover position-relative`} >
                        <div className="cursor-pointer me-2 text-truncate" onClick={()=>{setSession(session);loadConvo(session.id);setShowHistory(false)}}>{session.msg}</div>
                        <b className="position-absolute top-0 end-0 me-2 cursor-pointer" onClick={()=>removeSession(session.id)}>x</b>
                    </div>)}

                </Stack>
                }
            </Stack>
           : <Stack className="heybotnew-navbar-container align-items-start" gap={3}>
                <Image className="heybotnew-logo-image" src={logoImage}/>
                {KBases.length>0 &&
                <>
                    <Accordion className=" heybotnew-accordion w-100">
                            <Accordion.Item style={{borderRadius:'0.6rem'}} className="border-0" eventKey="0">
                                <Accordion.Header className={` d-flex mb-1 heybotnew-border-btn heybotnew-accordion-header  ${theme==0?"heybotnew-theme-light":"heybotnew-theme-dark"}`}>
                                    {currentKBase!=null&&<div class="m-0 d-flex justify-content-between w-100">
                                        {currentKBase.name}
                                        {/* <EditIcon className="heybotnew-image-small me-2" onClick={()=>loadKnowledgebase(currentKBase.id)}/> */}
                                    </div>}
            
                                </Accordion.Header>
                                <Accordion.Body className="p-0">
                                    <ListGroup style={{maxHeight:'8rem'}} className="heybotnew-scroll ">
                                    {KBases.filter((kbase) => kbase.id !== currentKBase.id).map((kbase)=><ListGroup.Item className="heybotnew-border-btn rounded d-flex justify-content-between" onClick={()=>setCurrentKBase(kbase)}><small >{kbase.name}</small></ListGroup.Item>)}
                                    </ListGroup>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
            
                </>
                }
                <div class="d-flex w-100 gap-2">
                    <div onClick={()=>{
                        setShowNewKBase(0);
                        setShowKBSettingModal(true);
                        setForVision(null)}}
                        className={`d-flex  heybotnew-primary-btn gap-2 `}>
                        <PlusWhiteIcon className="heybotnew-image-small" />
                        New Chatbot
                    </div>
                    {(currentKBase!=null&&currentKBase.id!=0)&&<div className={`d-flex heybotnew-secondary-btn gap-2 `} onClick={()=>{
                        loadKnowledgebase(currentKBase.id)
                        setShowKBSettingModal(true)}
                        }>
                        <EditIcon className="heybotnew-image-small me-2" />
                    </div>}
                </div>
                <div class="d-flex w-100 gap-2">
                    <div onClick={()=>{setSession({id:0,msg:''});setVisionImage("");setVisionImageAdded((prev)=>false);setChat([{isBot:true,msg:currentKBase.initMsg}])}} className={`d-flex gap-2 heybotnew-border-btn heybotnew-border-btn-hover`}>
                        <PlusIcon className="heybotnew-image-small" />
                        <div className="">New Conversation</div>
                    </div>
                    {/* <OverlayTrigger trigger="click" rootClose  placement="bottom-end" overlay={popover}>
                        <div className={`d-flex heybotnew-secondary-btn gap-2 `} >
                            <HistoryIcon className="heybotnew-image-small me-2" />
                        </div>
                    </OverlayTrigger> */}
                </div>
                <div class="d-flex w-100 gap-2">
                    <div onClick={()=>setShowHistory(true)} className={`d-flex gap-2 heybotnew-border-btn heybotnew-border-btn-hover`}>
                        <HistoryIcon className="heybotnew-image-small " />
                        <div className="">Previous Conversations</div>
                    </div>
                </div>
                {<div onClick={()=>setShowIntegrationModal(true)} className={`mt-auto d-flex gap-2 heybotnew-border-btn heybotnew-border-btn-hover`}>
                <IntegrationIcon className="heybotnew-image-small" />
                <div className="">Integrations</div>
            </div>}
            {(currentKBase!=null&&currentKBase.id!=0)&&<div onClick={()=>window.location.href = "/app/customize/"+currentKBase.slug} className={`d-flex gap-2 heybotnew-border-btn heybotnew-border-btn-hover`}>
                    <CustomizeIcon className="heybotnew-image-small" />
                    <div className="">Customize your chatbot</div>
                </div>}
                {(currentKBase!=null&&currentKBase.id!=0)&&<div onClick={()=>setShowShare(true)} className=" heybotnew-border-btn heybotnew-border-btn-hover" >
                            <ShareIcon className="heybotnew-image-small me-2" />
                            Share your chatbot
                        </div>}
            
            
            
                <div className={`heybotnew-border justify-content-between ${(currentKBase!=null&&currentKBase.id==0)}`}>
                    <div class="d-flex flex-column">
                        <div style={{fontSize:'0.875rem',fontWeight:'600'}}>{queries.limit-queries.current}</div>
                        <div style={{fontSize:'0.625rem'}}>Queries left</div>
                    </div>
                    <div onClick={()=>setShowReferral(true)} style={{width:'min-content'}} className={`d-flex heybotnew-primary-btn gap-1 `}>
                        <ReferIcon className="heybotnew-image-small" />
                        <div style={{fontSize:'0.625rem'}}>Queries</div>
                    </div>
            
                </div>
                <div onClick={()=>setShowUpgrade(true)} className={`d-flex  heybotnew-primary-btn gap-2 `}>
                        <JoinIcon className="heybotnew-image-small" />
                        Upgrade Membership
                </div>
                <Accordion className=" heybotnew-accordion-user heybotnew-user-btn w-100">
                    <Accordion.Item  className="border-0 w-100" eventKey="0">
                        <Accordion.Header className={` d-flex mb-1  heybotnew-accordion-header-user`}>
                            {userName}
            
                        </Accordion.Header>
                        <Accordion.Body className="p-0 py-3">
                            <ListGroup className="">
                            <div class="d-flex gap-1 align-items-center" onClick={logout}>
                                <LogoutIcon className="heybotnew-image-small" />
                                <small>Logout</small>
                            </div>
                            </ListGroup>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </Stack>}
        </x>
    )
}

export default Navbar
