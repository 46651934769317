import { useState,useRef, useEffect } from "react";
import {Button,Stack, Form, Image,Row,Col, FloatingLabel } from 'react-bootstrap'
import { ReactComponent as SendIcon } from "../assets/send-heybot.svg";
import '../styles/heybotnew.css'
import { useParams,useSearchParams } from "react-router-dom";
import axios from "axios";
import { ReactComponent as LightThemeIcon } from "../assets/light-mode-icon.svg";
import { ReactComponent as DarkThemeIcon } from "../assets/dark-mode-icon.svg";
import { ReactComponent as BackIcon } from "../assets/back.svg";
import { ToastContainer, toast } from 'react-toastify';


import logoImage from '../assets/embed-logo.png'
import { isStringValid } from "../utils/utility";


function HeyBotNewCustomize () {
    const { slug } = useParams();
    document.title = slug
    const [update,setUpdate] = useState(false);
    const [theme,setTheme] = useState(0);
    const [botColor,setBotColor] = useState({bg:"#362f2e",text:"white"});
    const [userColor,setUserColor] = useState({bg:"#FFF0EE",text:"black"});
    const [initMsg,setInitMsg] = useState("Hi there, ask me anything!");
    const [basePrompt,setBasePrompt] = useState("I want you to act as a document that I can have a conversation with. Your name is EmbedAI.");
    const [suggestions,setSuggestions] = useState(["What can you do?","What is this site about?"]);
    const [embedLogo,setEmbedLogo] = useState("");
    const [enableLeadGeneration,setEnableLeadGeneration] = useState(false);


    let saveTheme = () => {
        let getTheme = 'light'
        if(theme==true){
            getTheme = 'dark'
        }
        axios.post("/heybotnew/save_theme",{slug:slug,theme:getTheme,botColor:botColor,userColor:userColor,embedLogo:embedLogo}).then((res)=>{
            toast.success("Theme saved succesfully.")
            }).catch((err)=>{
            console.log(err)
            toast.error("Error saving theme. Please try again.")
        })

    }

    let savePrompts = () => {
        if(!isStringValid(initMsg)){
            toast.error("Please enter a valid initial message.")
        }
         if(!isStringValid(basePrompt)){
            toast.error("Please enter a valid base prompt.")
        }
         if(isStringValid(initMsg) && isStringValid(basePrompt)){
            axios.post("/heybotnew/save_prompts",{slug:slug,initMsg:initMsg,basePrompt:basePrompt,suggestions:suggestions}).then((res)=>{
                toast.success("Prompts saved succesfully.")
            }).catch((err)=>{
            console.log(err)
            toast.error("Error saving prompts. Please try again.")
        })
        }
    }

    const changeBrandLogo = (value) => {
        axios
          .post("/heybotnew/save_logo", { slug: slug, url: value })
          .then((res) => {
            setEmbedLogo(res.data);
          })
          .catch(function (error) {
            console.log("Update Failed");
          });
      };

    const uploadImage = (file) => {

        axios
          .post("/heybotnew/upload_logo", { slug: slug, filename: file.name } )
          .then((res) => {
            res = res.data;
            let url = res.url;
            let fields = res.fields;
            let formData = new FormData();
            for (let key in fields) {
              formData.append(key, fields[key]);
            }
            formData.append("file", file);
            const xhr = new XMLHttpRequest();
            xhr.addEventListener("loadend", uploadComplete, false);
            function uploadComplete(event) {
              let result = fields["key"];
              changeBrandLogo(result);
            }
            xhr.open("POST", url, true);
            xhr.send(formData);
          })
          .catch(function (error) {
            console.log("Image Upload Failed");
          });
    };

    const handleLeadGenerationSwitch = ()=>{
        
        axios
          .post("/heybotnew/set_lead_generation", { slug: slug, is_enabled: !enableLeadGeneration })
          .then((res) => {
            console.log("success")
            setEnableLeadGeneration(!enableLeadGeneration)
          })
          .catch(function (error) {
            console.log("Update Failed");
          });
    }

    const handleDownloadLeadsData = (event)=>{
        event.preventDefault()
        window.open("https://embedai.thesamur.ai/heybotnew/generate_leads_file?slug="+slug,'_blank', 'noreferrer')
    }
      
    useEffect(()=>{
        axios.get("/heybotnew/get_chatbot_info",{params:{slug:slug}}).then((res)=>{
            console.log(res.data)
            if('dark'===res.data.theme[0]){
                setTheme(1)
            }
            setBotColor({bg:res.data.theme[1],text:res.data.theme[2]})
            setUserColor({bg:res.data.theme[3],text:res.data.theme[4]})
            setInitMsg(res.data.initial_message)
            setBasePrompt(res.data.base_prompt)
            setSuggestions(res.data.suggestions)
            setEmbedLogo(res.data.embed_logo)
            if(res.data.lead_generation_enabled!=null){
                if(res.data.lead_generation_enabled===0){
                    
                    setEnableLeadGeneration(false)
                }else{
                    setEnableLeadGeneration(true)
                }
            }
            }).catch((err)=>{
            console.log(err)
        })
    },[])

    return(
        <div className="heybotnew-bg d-flex flex-column py-2 p-lg-3 ">
            <Row className="g-0 flex-grow-1 pb-2">
                <Col className="d-flex px-2 flex-column" lg={8}>
                    <div class="d-flex align-items-center mb-3 gap-2 align-self-start" onClick={()=>window.location.href="/heybotnew"}>
                        <div class={`rounded-circle heybotnew-box heybotnew-theme-light p-2`}>
                            <BackIcon className="heybotnew-image" style={{cursor:"pointer"}}/>
                        </div>
                        <h4 className="m-0 fw-bold">Back</h4>
                    </div>
                    <div className={`heybotnew-box heybotnew-theme-light p-3 flex-grow-1`}>
                        <Stack className="overflow-auto h-100" gap={3}>

                            <Stack direction="horizontal"><span>Modify initial message </span>
                            <span className="ms-2" style={{color:"red"}}>*</span>
                            </Stack>
                            <FloatingLabel
                                className="w-100"
                                label="Initial Message"
                            >
                                <Form.Control value={initMsg} onChange={(e)=>setInitMsg(e.target.value)} placeholder="Initial Message" className={` px-lg-3 heybotnew-input theme-light`}/>
                            </FloatingLabel>
                            <div className="heybotnew-divider-horizontal"></div>

                            <Stack direction="horizontal"><span>Modify base prompt </span>
                            <span className="ms-2" style={{color:"red"}}>*</span>
                            </Stack>
                            <FloatingLabel
                                className="w-100"
                                label="Base Prompt"
                            >
                                <Form.Control style={{minHeight:'6rem'}} value={basePrompt} as="textarea" onChange={(e)=>setBasePrompt(e.target.value)} placeholder="Initial Message" className={` px-lg-3 heybotnew-input theme-light`}/>
                            </FloatingLabel>
                            <div className="heybotnew-divider-horizontal"></div>

                            <span> Modify suggestions for users </span>
                            {suggestions.map((link,index)=><FloatingLabel
                                className="w-100"
                                label="Suggestion"
                            >
                                <Form.Control value={suggestions[index]} onChange={(e)=>{suggestions[index]=e.target.value;setUpdate((prev)=>!prev)}} placeholder="Suggestion" className={` px-lg-3 heybotnew-input theme-light`}/>
                            </FloatingLabel>)}
                            {slug!=="embedai-writeup"?<>
                            <div className="heybotnew-divider-horizontal"></div>
                            <div className="d-flex">
                            <span>Enable lead generation</span>
                            <Form.Check type="switch" className="ms-2" checked={enableLeadGeneration} onChange={()=>handleLeadGenerationSwitch()}/>
                            </div>

                            <div className="d-flex mt-1 align-items-center">
                            <span>Download Leads data</span>
                            <Button size="sm" className="heybotnew-share-button ms-3 p-2" onClick={(e)=>handleDownloadLeadsData(e)}>Download</Button>
                            </div>
                            </>:""}

                            <Button className="heybotnew-share-button heybotnew-general-btn mt-auto p-2" onClick={savePrompts}>
                                Save Prompts
                            </Button>
                        </Stack>
                    </div>

                </Col>
                <Col className="px-2 d-flex flex-column gap-2 align-items-end " lg={4}>
                    <h4 className="m-0 fw-bold align-self-start mt-2">Preview</h4>
                    <div className={`heybotnew-preview w-100 heybotnew-box embed-shadow  ${theme==0?"heybotnew-theme-light":"heybotnew-theme-dark"} p-3`}>
                        <Stack className="h-100" gap={3}>
                            <div className="d-flex flex-column gap-4 heybotnew-scroll heybotnew-chat-section flex-grow-1" style={{flex:'1 1 0'}}>
                             
                                <div style={{background:botColor.bg,color:botColor.text,borderRadius:'1rem',borderTopLeftRadius:0}} className={` align-self-start heybotnew-answer heybotnew-msg-container `}>
                                    <div>{initMsg}</div>
                                </div>
                                <div style={{background:userColor.bg,color:userColor.text,borderRadius:'1rem',borderTopRightRadius:0}} className={` align-self-end heybotnew-answer heybotnew-msg-container `}>
                                    <div>Hello !</div>
                                </div>
                                <div className="end-chat mt-auto"></div>
                            </div>
                            <div class="d-flex flex-column heybotnew-input-container w-100 pt-2 gap-2">
                                <div class="d-flex overflow-auto gap-1">{suggestions.map((suggestion)=><>{suggestion!=""?<div  style={{background:botColor.bg,color:botColor.text}} className={`border text-nowrap heybotnew-answer heybotnew-suggestion`}>{suggestion}</div>:""}</>)}</div>
                                <div class="d-flex position-relative">
                                    <Form.Control  className={`px-3 pe-5 py-2 heybotnew-input ${theme==0?"theme-light":"theme-dark"}`} placeholder="Ask a question"/>
                                    <Button  className={`p-2 position-absolute end-0 mt-2 me-2 heybotnew-button heybotnew-send-btn border-0 ${theme==1?"heybotnew-theme-light":"heybotnew-theme-dark"}`} variant="primary">
                                        <svg className="heybotnew-image" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" id="send-alt" class="icon glyph" fill={theme==0?"#FFF0EE":"#362f2e"}>
                                            <g id="SVGRepo_bgCarrier" stroke-width="0"/>
                                            <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"/>
                                            <g id="SVGRepo_iconCarrier">
                                            <path d="M21.88,4.73,16.2,20.65A2,2,0,0,1,14.3,22h0a2,2,0,0,1-1.9-1.31l-2.12-5.52,1.54-1.54,2.49-2.49a1,1,0,1,0-1.42-1.42l-2.49,2.49L8.82,13.76,3.31,11.63a2,2,0,0,1,0-3.83L19.27,2.12a2,2,0,0,1,2.61,2.61Z" style={theme===0?{fill:'#FFF0EE'}:{fill:'#362f2e'}}/>
                                            </g>
                                        </svg>
                                    </Button>
                                </div>
                            </div>
                        </Stack>
                    </div>
                    <div className={`p-2 align-self-end  d-flex gap-2 align-items-center heybotnew-box ${theme==0?"heybotnew-theme-light":"heybotnew-theme-dark"} cursor-pointer `}>
                        <Image src={embedLogo==""?logoImage:embedLogo} className=" heybotnew-embed-image" />
                    </div>
                    <div className={`heybotnew-box flex-grow-1 heybotnew-theme-light mt-4 p-3 w-100`}>
                        <Stack className="h-100" gap={3}>
                            <div className="d-flex align-items-center gap-2">
                                <b>Theme</b>
                                <div class="d-flex">
                                    <LightThemeIcon className="heybotnew-image" />
                                    <Form.Check type="switch" id="theme-switch"  className="d-flex justify-content-center"  checked={theme} onChange={()=>{theme==0?setTheme((prev)=>1):setTheme((prev)=>0)}}/>
                                    <DarkThemeIcon className="heybotnew-image" />
                                </div>
                            </div>
                            <div>
                                <b>Bot</b>
                                <div className="d-flex align-items-center gap-2">
                                    <small>Message Background</small>
                                    <Form.Control
                                        value={botColor.bg}
                                        onChange={(e)=>setBotColor({bg:e.target.value,text:botColor.text})}
                                        type="color"
                                        id="exampleColorInput"
                                    />
                                     <div class="d-flex ms-auto">
                                        <small>Text White</small>
                                        <Form.Check type="switch" id="theme-switch"  className="d-flex justify-content-center"  checked={botColor.text=='black'?true:false} onChange={(e)=>{e.target.checked?setBotColor({bg:botColor.bg,text:'black'}):setBotColor({bg:botColor.bg,text:'white'})}}/>
                                        <small>Text Black</small>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <b>User</b>
                                <div className="d-flex align-items-center gap-2">
                                    <small>Message Background</small>
                                    <Form.Control
                                        value={userColor.bg}
                                        onChange={(e)=>setUserColor({bg:e.target.value,text:userColor.text})}
                                        type="color"
                                        id="exampleColorInput"
                                    />
                                    <div class="d-flex ms-auto">
                                        <small>Text White</small>
                                        <Form.Check type="switch" id="theme-switch"  className="d-flex justify-content-center"  checked={userColor.text=='black'?true:false} onChange={(e)=>{e.target.checked?setUserColor({bg:userColor.bg,text:'black'}):setUserColor({bg:userColor.bg,text:'white'})}}/>
                                        <small>Text Black</small>
                                    </div>
                                </div>
                            </div>

                            <div className="d-flex align-items-center gap-2">
                                    <b>Logo</b>
                                    <Form.Control
                                        type="file"
                                        id="exampleColorInput"
                                        className="ms-auto"
                                        onChange={(e) => uploadImage(e.target.files[0])}
                                    />
                            </div>
                            <Button className="heybotnew-share-button heybotnew-general-btn mt-auto p-2" onClick={saveTheme}>
                                Save Theme
                            </Button>
                          
                        </Stack>
                    </div>

                </Col>
            </Row>
            <ToastContainer/>
        </div>
    )

}
export default HeyBotNewCustomize;