import { useState,useRef,useEffect } from "react";
import {toast} from 'react-toastify'
import BasicOnboarding from "../components/heybotnew/BasicOnboarding";
import axios from "axios";
import CustomizeOnboarding from "../components/heybotnew/CustomizeOnboarding";
import ShareOnboarding from "../components/heybotnew/ShareOnboarding";
import Cookies from "js-cookie";
function Onboarding() {
    const [loadPercent,setLoadPercent] = useState({current:0,total:0})
    const [KBase,setKBase] = useState({id:0,slug:'',name:'',embed:'',direct:''})
    const [step,setStep] = useState(0)
    const [newLinkPages, setNewLinkPages] = useState([{"index":0,"link_url":"","pages":1}]);
    const [trainingFinished,setTrainingFinished] = useState(false)
    const loadRef = useRef(null)
    loadRef.current = loadPercent

    let startTraining = (name,link) => {
        let size = 0
        let links = [link]
        let removedSources = []
        size = 1000
        setStep((prev)=>1)
        setLoadPercent({current:0,total:size})
        const newLinkPages = [{"index":0,"link_url":link,"pages":1}];
        let loadTimeout = setInterval(()=>setLoadPercent({current:loadRef.current.current+(Math.random() * (150 - 50) + 50),total:size}),2500)
        axios.post("/heybotnew/load_knowledgebase",{files:[],links:links.toString(),removedSources:removedSources.toString(),name:name,id:KBase.id,addNotionData:'',link_pages:JSON.stringify(newLinkPages)}, {
            headers: {
            'Content-Type': 'multipart/form-data'
            }}).then((res)=>{
              let copyCode = `<script id="embedai" src="https://embedai.thesamur.ai/embedai.js" data-id="${res.data.slug}"></script>`
              let copyCodeSimple = "https://embedai.thesamur.ai/embedai/embed/"+res.data.slug+"?simple=true"
              setKBase({id:res.data.id,slug:res.data.slug,name:res.data.slug,embed:copyCode,direct:copyCodeSimple})
              clearTimeout(loadTimeout)
              setTrainingFinished((prev)=>true)
              toast.success("Knowlegdebase created successfully")
              console.log(KBase)
          }).catch((err)=>{
            setKBase({id:err.response.data.id,...KBase})

            // setKBaseError(err.response.data.error)
            clearTimeout(loadTimeout)
            if(err.response.data.error!=undefined&&err.response.data.error!=""){
                toast.success(err.response.data.error)
            }else{
                toast.error("Error creating Knowlegdebase")

            }
          
        })
        
    }


    useEffect(() => {
        if(Cookies.get('botSlug')){
            axios.post("/heybotnew/transfer_kb",{slug:Cookies.get('botSlug')}, {
                headers: {
                'Content-Type': 'application/json'
                }}).then((res)=>{
                toast.success("Bot successfully linked to the account.")
                Cookies.remove('BotSlug')
                window.location.href = "https://embedai.thesamur.ai"
              }).catch((err)=>{
                if (err.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    console.log(err.response.data);
                    toast.error(err.response.data)
                    // console.log(error.response.status);
                    // console.log(error.response.headers);
                } else if (err.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the 
                    // browser and an instance of
                    // http.ClientRequest in node.js
                    console.log(err.request);
                    toast.error("Error linking bot to the account.")
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', err);
                    toast.error("Error linking bot to the account.")
                }

                console.log(err)

            })
        }
    }, [])

    return (
        <div className="bg d-flex flex-column p-lg-2 p-xxl-4 ">
          <script id="embedai" src="https://embedai.thesamur.ai/embedai.js" data-id="embedai"></script>
            <div className=" main h-100 p w-100" >
                {step==0?
                    <BasicOnboarding startTraining={startTraining}/>:step==1?
                    <CustomizeOnboarding slug={KBase.slug} loadPercent={loadRef.current} trainingFinished={trainingFinished} setStep={setStep}/>:
                    <ShareOnboarding KBase={KBase}/>
                }
            </div>
        </div>

    );
}

export default Onboarding;
